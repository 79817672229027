<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedExamens"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add_examen_clinique'}"
          class="btn btn-outline-primary btn-icon"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
    <modal
      id="detail-examen-clinique"
      title="Résumé examen clinique"
      size="x-large"
    >
      <review-examen />
    </modal>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import DataTable from '../../../components/dataTable/local.vue'
import navbar from '../../../components/navbar.vue'
import {  TEXT_TYPE, DATE_TYPE, COMPONENT_TYPE} from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
import ReviewExamen from '../../../components/sanitaire/questionnaire/review.vue'
const Animal = () => import('../../../components/sanitaire/animal.vue')
const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
const Actions = () => import('../../../components/sanitaire/questionnaire/list/actions.vue')
export default {
    components: {navbar, DataTable, Modal, ReviewExamen },
    data(){
        return {
          navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Examen clinique',
                }
                ],
                pageIcon: 'la-heartbeat',
                pageTitle: 'Examen clinique',
                pageDescription: 'List des examens cliniques',  
            nom: null,
            libelle: null,
            description: null,
            model: null
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        })
    },
    computed: {
        ...mapGetters({
            examenCliniques: 'sanitaire/examenCliniques',
            is_veterinaire: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        }),
        sortedExamens(){
            return [...this.examenCliniques].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Createur', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                {label: 'Date de création', name: 'createdAt', type: DATE_TYPE, sortable: true},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        },
        canAdd(){
          return this.is_super_admin || this.is_veterinaire || this.is_assistant_veterinaire || this.is_assistant_veto_major
        }
    }
}
</script>